import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import strings from "../../core/translate";

class RequestList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.pathname = "request";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }



    async handleDelete(valueid) {
        if(window.confirm("Are you sure you want to delete ? ")){
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem("transaction",valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }
    }

    async handleApprove(valueid) {
        if(window.confirm("Are you sure you want to Approve ? ")){
            this.props.loadingAction(true)
            const result = await this.engine.getItem(this.pathname+"/approve/",valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }
    }

    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/transaction/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                {strings.actions}
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>{strings.edit}</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleApprove(item._id)}>Approve</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>{strings.delete}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage() {
        const response = await this.engine.getItem(this.pathname);

        if (response && response.status === 200) {
            const now = new Date();
            const filteredData = response.data.data.filter(item => {
                const createdAt = new Date(item.createdAt);
                const twentyMinutesAgo = new Date(now.getTime() - 20 * 60 * 1000);

                return (
                    item.transactiontype != "card-member" || item.status != "pending" || (item.transactiontype == "card-member" &&
                    item.status === "pending" &&
                    createdAt > twentyMinutesAgo)
                );
            });


            this.setState({
                isLoading: false,
                dataSource: filteredData.map((item, key) => {
                    return {
                        id: key,
                        data: item,
                        code: item.code,
                        amount: item.amount,
                        user: item.user?.username,
                        transactiontype: item.transactiontype,
                        brokerId: item.brokerId,
                        status: item.status,
                        created: this.renderDate(item.createdAt),
                        actions: this.handleActions(key, item),
                    };
                })
            });
        }

        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }

    handleGlobalFilterChange = event => {
        const { name, value } = event.target;
        if (name === 'globalFilter') {
            this.setState({ globalFilter: value });
        } else if (name === 'startDateFilter' || name === 'endDateFilter') {
            this.setState({ [name]: value });
        }
    };

    render() {
        const { dataSource, isLoading, globalFilter, startDateFilter, endDateFilter } = this.state;

        let filteredData = [...dataSource];

        if (globalFilter) {
            filteredData = filteredData.filter(row =>
                Object.values(row.data).some(
                    value =>
                        value &&
                        String(value)
                            .toLowerCase()
                            .includes(globalFilter.toLowerCase())
                )
            );
        }


        if (startDateFilter && endDateFilter) {
            const startDate = new Date(startDateFilter);
            const endDate = new Date(endDateFilter);
            endDate.setDate(endDate.getDate() + 1);

            filteredData = filteredData.filter(row =>
                new Date(row.created) >= startDate &&
                new Date(row.created) < endDate
            );
        }

        let columns = [

            {
                Header: "amount",
                accessor: "amount"
            },
            {
                Header: "user",
                accessor: "user"
            },
            {
                Header: "transactiontype",
                accessor: "transactiontype"
            },
            {
                Header: "receipt",
                accessor: "code"
            },


            {
                Header: "status",
                accessor: "status"
            },
            {
                Header:strings.created,
                accessor: "created",
            },
            {
                Header: strings.actions,
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">
                                        {" "}
                                    </CardTitle>
                                    <h3 className="pull-left">Recent Transaction</h3>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight primary-pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(RequestList);
