import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import Constants from "../../core/Constants";
import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import moment from "moment";
import {Utilites} from "../../core/Utilites";



export default class UserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            _id:"",
            email:"",
            membershipregistredsince:"",
            first_name:"",
            createdAt:"",
            last_name:"",
            secretcode:"",
            username:"",
            balance:"0",
            credit:"0",
            creditnovember:"0",
            coins:"",
            myreferral:"",
            referral:"",
            country:"",
            rank:"",
            ranklist:[],
            nationalId:"",
            ismember:false,
            mfa:false,
            membershipexpiry:"",
            status:"active",
            history:[],
            loading : false,
            logs:[],
            transition:[]
        };
        this.pathname = "user";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){

            const membershipexpiry = edit.membershipexpiry ?moment(edit.membershipexpiry).format('YYYY-MM-DD'):"";
            const membershipregistredsince = edit.membershipregistredsince ?moment(edit.membershipregistredsince).format('YYYY-MM-DD'):"";
            this.setState({
                ...edit,
                membershipexpiry,
               // membershipregistredsince
            });
        }
        this.prepareData();
    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("rank");
        if (responseuni) {
            const data = responseuni.data.data;
            let ranklist = [];
            ranklist.push({ value: "", label: "none" });
            data.map((p) => {
                ranklist.push({ value: p._id, label: p.title });
            });
            this.setState({
                isLoading:false,
                ranklist,
            });
        }

        const edit = this.props.location.data

        responseuni = await this.engine.getItemlistDirect("logs/",edit?._id);
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                isLoading:false,
                logs:data,
            });
        }


        responseuni = await this.engine.getItemlistDirect("transactionx/",edit?._id);
        if (responseuni) {
            const data = responseuni.data.data;
            this.setState({
                isLoading:false,
                transition:data,
            });
        }



    };


    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        try {
            let data  = {...this.state}

            const response = await this.engine.saveItem(this.pathname,data)

            if(response && response.status === 200){
                this.props.history.push('/admin/'+this.pathname);
            }
        }catch (e) {
            Utilites.showErrorMessage("Please check the data you are saving!")
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading, balance,email,myreferral,
            referral,
            country,
            nationalId,
            username,
            ismember,
            first_name,
            last_name,
            membershipregistredsince,
            membershipexpiry,
            mfa,
            creditnovember,
            transition,
            secretcode,
            ranklist,
            credit,
            coins,
            createdAt,
            logs,
            rank,
            status }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }




        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                       <FormRow label={"balance"}  name="balance" data={balance.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"first_name"}  name="first_name" data={first_name} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"last_name"}  name="last_name" data={last_name} changeInput={this.changeInput.bind(this)}  />

                                        <FormRow label={"username"}  name="username" data={username} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"email"} iss name="email" data={email} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"myreferral"}  name="myreferral" data={myreferral} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"referral"} required={false}  name="referral" data={referral} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"credit"}  name="credit" data={credit.toString()} changeInput={this.changeInput.bind(this)}  />

                                        <FormRow label={"secret code"} required={false}  readonly={true}  name="secretcode" data={secretcode.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"creditnovember"}  name="creditnovember" data={creditnovember.toString()} changeInput={this.changeInput.bind(this)}  />


                                        <FormRow label={"coins"}  name="coins" data={coins.toString()} changeInput={this.changeInput.bind(this)}  />

                                        <SelectRow
                                            label={"rank"}
                                            name="rank"
                                            defaultValue={rank}
                                            data={ranklist}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <SelectRow
                                            label={"country"}
                                            name="country"
                                            defaultValue={country}
                                            data={this.createSelectValue(Constants.countries,false,true)}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        <FormRow label={"nationalId"}  name="nationalId" data={nationalId} changeInput={this.changeInput.bind(this)}  />
                                        <SelectRow
                                            label={"status"}
                                            name="status"
                                            defaultValue={status}
                                            data={this.createSelectValue(["active","not active","banned"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />



                                        <Checkboxrow label="mfa" name="mfa" data={mfa}  changeInput={this.changeInput.bind(this)} />

                                        <Checkboxrow label="is member" name="ismember" data={ismember}  changeInput={this.changeInput.bind(this)} />
                                        {ismember.toString()=="true"  ?<FormRow  label={"membership start since "} required={false}  name="membershipregistredsince" data={membershipregistredsince} changeInput={this.changeInput.bind(this)}  />:<div/>}
                                        {ismember.toString()=="true" ?<FormRow type={"date"} label={"membership expiry date"}  name="membershipexpiry" data={membershipexpiry} changeInput={this.changeInput.bind(this)}  />:<div/>}


                                        <FormRow label={"registred at"} readonly={true}  name="createdAt" data={this.renderDate(createdAt)}   />

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>


                            <CardBody style={{height: 300,
                                overflow: "scroll"}}>
                                <Table responsive >
                                    <thead>
                                    <tr>
                                        <th>Log</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {logs.map((item,index)=>{

                                        return <tr key={index}>
                                            <td>{item.msg}</td>
                                            <td> {this.renderDate(item.createdAt)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>



                                    </tbody>
                                </Table>
                            </CardBody>


                            <CardBody style={{height: 300,
                                overflow: "scroll"}}>
                                <Table responsive >
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transition.map((item,index)=>{

                                        return <tr key={index}>
                                            <td>{item.name}</td>
                                            <td> {this.renderDate(item.createdAt)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>



                                    </tbody>
                                </Table>
                            </CardBody>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


