import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import strings from "../../core/translate";

class ClientList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.pathname = "transaction";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }



    async handleDelete(valueid) {
        if(window.confirm("Are you sure you want to delete ? ")){
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }
    }

    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                {strings.actions}
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>{strings.edit}</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>{strings.delete}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {

                        return {
                            id: key,
                            data:item,
                            code: item.code ,
                            amount:item.amount,
                            email:item.user?.email,
                            user:item.user?.username,
                            transactiontype:item.transactiontype,
                            brokerId:item.brokerId,
                            status:item.status,
                            created: this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }

    handleGlobalFilterChange = event => {
        const { name, value } = event.target;
        if (name === 'globalFilter') {
            this.setState({ globalFilter: value });
        } else if (name === 'startDateFilter' || name === 'endDateFilter') {
            this.setState({ [name]: value });
        }
    };

    render() {
        const { dataSource, isLoading, globalFilter, startDateFilter, endDateFilter, transactionTypeFilter } = this.state;

        // Filter data based on globalFilter, date range, and transactionTypeFilter
        let filteredData = [...dataSource];

        if (globalFilter) {
            filteredData = filteredData.filter(row =>
                Object.values(row.data).some(
                    value =>
                        value &&
                        String(value)
                            .toLowerCase()
                            .includes(globalFilter.toLowerCase())
                )
            );
        }

        if (startDateFilter && endDateFilter) {
            const startDate = new Date(startDateFilter);
            const endDate = new Date(endDateFilter);
            endDate.setDate(endDate.getDate() + 1);

            filteredData = filteredData.filter(row =>
                new Date(row.created) >= startDate &&
                new Date(row.created) < endDate
            );
        }

        if (transactionTypeFilter) {
            filteredData = filteredData.filter(row => row.transactiontype === transactionTypeFilter);
        }

        let columns = [
            {
                Header: "amount",
                accessor: "amount"
            },
            {
                Header: "user",
                accessor: "user"
            },
            {
                Header: "email",
                accessor: "email"
            },
            {
                Header: "transactiontype",
                accessor: "transactiontype"
            },
            {
                Header: "receipt",
                accessor: "code"
            },
            {
                Header: "status",
                accessor: "status"
            },
            {
                Header: strings.created,
                accessor: "created",
            },
            {
                Header: strings.actions,
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ];

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">
                                        {" "}
                                    </CardTitle>
                                    <h3 className="pull-left">Transaction</h3>
                                    <div className="pull-right">
                                        {/* Filter Buttons */}

                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "topup-membership" })}
                                        >
                                            topup-membership
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "card-member" })}
                                        >
                                            card-member
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "usdt" })}
                                        >
                                            USDT
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "balance-membership" })}
                                        >
                                            Balance-Membership
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "send" })}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ transactionTypeFilter: "receive" })}
                                        >
                                            Receive
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={() => this.setState({ transactionTypeFilter: null })}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={filteredData}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight primary-pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ClientList);
