import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import SelectRow from "../../components/Select/SelectRow";
import DynamicTbl from "../question/DynamicTbl";


export default class MissionAddUpdate extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            variationlist:[],
            orderx:"0",
            vimeo:"",
            missiontype:"task",
            coin:"0"
        };
        this.pathname = "mission";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {

            this.setState({
                ...edit,
            });
        }
    }
    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })

        let data = { ...this.state }

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })

    };

    fillAttribute(data){
        this.setState({
            variationlist:data
        })
    }

    render() {
        // taking all the states
        const { loading, isLoading,  title,day,
            orderx,
            vimeo,
            variationlist,
            missiontype,
            coin} = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        const days = Array.from({ length: 90 }, (_, i) => `day ${i + 1}`);

        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label={"task name "} name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"task order"} name="orderx" data={orderx} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"coin"} name="coin" data={coin.toString()} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow
                                            label={"missiontype"}
                                            name="missiontype"
                                            defaultValue={missiontype}
                                            data={this.createSelectValue(["task","video","questions"])}
                                            changeInput={this.changeInput.bind(this)}
                                        />
                                        {missiontype.includes("video") &&<FormRow label={"vimeo link"} required={false} name="vimeo" data={vimeo} changeInput={this.changeInput.bind(this)} />}


                                        {missiontype.includes("questions") && <DynamicTbl fillAttribute={(e)=>{this.fillAttribute(e)}}  colx={["question", "answers","correct_answer"]}  initialdata={variationlist}/>}

                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


