import React, { Component } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import strings from "../../core/translate";

class UserMissionList extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            filteredData:[],
            dataSource: [], // Added to store lesson summary data
            selectedStatuses: ["pending"],
        };
        this.pathname = "missionuser";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
    }

    async handleDelete(valueid) {
        if (window.confirm("Are you sure you want to delete?")) {
            this.props.loadingAction(true);
            const result = await this.engine.deleteItem(this.pathname, valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }

    async handleDeleteday(valueid) {
        if (window.confirm("Are you sure you want to delete all task for this day?")) {
            this.props.loadingAction(true);
            const result = await this.engine.deleteItemday(this.pathname, valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }

    handleEdit(index, dataid) {
        const data = this.state.dataSource[index].data;

        this.props.history.push({
            pathname: "/admin/" + this.pathname + "/edit/" + dataid,
            data,
        });
    }

    handleActions(index, item) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    className="btn-round btn-block"
                    color="primary"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                    type="button"
                >
                    {strings.actions}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        eventKey="2"
                        onClick={() => this.handleEdit(index, item._id)}
                    >
                        {strings.edit}
                    </DropdownItem>
                    <DropdownItem
                        variant="danger"
                        eventKey="1"
                        onClick={() => this.handleDelete(item._id)}
                    >
                        {strings.delete}
                    </DropdownItem>

                    <DropdownItem
                        variant="danger"
                        eventKey="1"
                        onClick={() => this.handleDeleteday(item._id)}
                    >
                       delete all task for this day
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    async callPage() {
        const response = await this.engine.getItem(this.pathname);

        if (response && response.status === 200) {
            const courseData = response.data.data;
            this.setState({
                isLoading: false,
                dataSource: courseData.map((item, key) => ({
                    id: key,
                    data: item,
                    coin:item.coin,
                    username: item.user?.username,
                    title: item.mission?.title,
                    status: item.status,
                    day: item.day?.title,
                    missiontype:item?.mission.missiontype,
                    picture:item.fullpicture && item.fullpicture.length>1 ? <img key={key}  src={item.fullpicture} className={"imgsponser"} />:"-" ,
                    created: this.renderDate(item.createdAt),
                    updatedAt: item.updatedAt==item.createdAt?"-":this.renderDate(item.updatedAt),
                    actions: this.handleActions(key, item),
                })),
                filteredData:courseData.map((item, key) => ({
                    id: key,
                    data: item,
                    coin:item.coin,
                    username: item.user?.username,
                    missiontype:item?.mission.missiontype,
                    title: item.mission?.title,
                    day: item.day?.title,
                    status: item.status,
                    picture:item.fullpicture && item.fullpicture.length>1 ? <img key={key}  src={item.fullpicture} className={"imgsponser"} />:"-" ,
                    created: this.renderDate(item.createdAt),
                    updatedAt: item.updatedAt==item.createdAt?"-":this.renderDate(item.updatedAt),
                    actions: this.handleActions(key, item),
                })),
            },()=>{
                this.handleFilterByStatus(["pending"])
            });
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage();
    }

    handleFilterByStatus(selectedStatuses) {
        this.setState((prevState) => ({
            selectedStatuses,
            filteredData: prevState.dataSource.filter((item) =>
                selectedStatuses.length === 0 || selectedStatuses.includes(item.status)
            ),
        }));
    }

    render() {
        let {
            filteredData,
            isLoading,
            selectedStatuses,
        } = this.state;

        let columns = [


            {
                Header: "username",
                accessor: "username",
            },
            {
                Header: "day",
                accessor: "day",
            },
            {
                Header: "missiontype",
                accessor: "missiontype",
            },


            {
                Header: "Completed Date",
                accessor: "created",
            },
            {
                Header: "Updated Date",
                accessor: "updatedAt",
            },
            {
                Header: strings.actions,
                accessor: "actions",
                sortable: false,
                filterable: false,
            },
        ];

        return (
            <div className="content">

                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="12" >

                                        <h6>Filter by :</h6>
                                        <Input
                                            style={{width:200}}
                                            type="select"
                                            multiple
                                            className={"custom-select-input"}
                                            value={selectedStatuses}
                                            onChange={(e) => {
                                                const options = Array.from(e.target.options).filter(o => o.selected).map(o => o.value);
                                                this.handleFilterByStatus(options);
                                            }}
                                        >
                                            <option value="completed">Completed</option>
                                            <option value="pending">Pending</option>
                                            <option value="rejected">Rejected</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={filteredData}
                                    defaultFilterMethod={this.filterCaseInsensitive}
                                    filterable
                                    loading={isLoading}
                                    columns={columns}
                                    defaultPageSize={100}
                                    showPaginationBottom={true}
                                    className="-striped -highlight primary-pagination"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
});

export default connect(null, mapDispatchToProps)(UserMissionList);
