import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    Input, PaginationLink, PaginationItem, Pagination,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine";
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import strings from "../../core/translate";

class ClientList extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            iscustomer:false,
            ismember:false,
            page:0,
            count:0,
            pageSize:10,
            limitedaccess:true
        };
        this.pathname = "user";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
        const managertype = sessionStorage.getItem('managertype');

        if(managertype.includes("admin")){
            this.setState({
                limitedaccess: false
            })
        }


    }

    async handleDelete(valueid) {
        if (window.confirm("Are you sure you want to delete ?")) {
            this.props.loadingAction(true);
            const result = await this.engine.deleteItem(this.pathname, valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }

    async handleToggleStatus(valueid,status) {
        if (window.confirm("Are you sure you want to "+status+" ?")) {
            this.props.loadingAction(true);
            const result = await this.engine.getItem("status/"+status+"/", valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }
    async handleResend(valueid) {
        if (window.confirm("Are you sure you want to resend secret code email ?")) {
            this.props.loadingAction(true);
            const result = await this.engine.getItem("user/resendmail/", valueid);
            if (result) {
                this.handlePage();
            }
            this.props.loadingAction(false);
        }
    }

    handleEdit(index, dataid) {
        const data = this.state.dataSource[index].data;

        this.props.history.push({
            pathname: "/admin/" + this.pathname + "/edit/" + dataid,
            data,
        });
    }
    handleMonth(index, dataid) {
        const data = this.state.dataSource[index].data;

        this.props.history.push({
            pathname: "/admin/" + this.pathname + "/month/" + dataid,
            data,
        });
    }

    handleActions(index, item) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    className="btn-round btn-block"
                    color="primary"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                    type="button"
                >
                    {strings.actions}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        eventKey="2"
                        onClick={() => this.handleEdit(index, item._id)}
                    >
                        View User Info
                    </DropdownItem>
                    <DropdownItem
                        eventKey="2"
                        onClick={() => this.handleMonth(index, item._id)}
                    >
                        Monthly Report
                    </DropdownItem>
                    {item.status=="active" ?<DropdownItem
                        eventKey="2"
                        onClick={() => this.handleToggleStatus( item._id,"banned")}
                    >
                        Ban User
                    </DropdownItem>:<DropdownItem
                        eventKey="2"
                        onClick={() => this.handleToggleStatus( item._id,"active")}
                    >
                        Activate User
                    </DropdownItem>}
                    <DropdownItem
                        eventKey="2"
                        onClick={() => this.handleResend( item._id)}
                    >
                        Resend Secret Code Email
                    </DropdownItem>

                    <DropdownItem
                        variant="danger"
                        eventKey="1"
                        onClick={() => this.handleDelete(item._id)}
                    >
                        {strings.delete}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    async callPage(page=0,pageSize=10,filtered = []) {


            const {iscustomer,ismember } = this.state


        const response = await  this.engine.postItem("user","/list/"+page+"/"+pageSize+"?iscustomer="+iscustomer+"&ismember="+ismember,filtered);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    count:response.data.data.count,
                    page:page,
                    pageSize:pageSize,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            email: item.email ,
                            first_name: item.first_name ,
                            status: item.status ,
                            ismember:item.ismember ? "YES":"NO",
                            credit:item.credit ,
                            username:item.username,
                            myreferral:item.myreferral,
                            creditdecember:item.creditdecember,
                            last_name:item.last_name,
                            referral: item.referral,
                            membershipexpiry:item.ismember?this.renderDate(item.membershipexpiry):"-",
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }


    handlePage(page) {
        this.props.loadingAction(true);
        this.callPage(page)
    }



    render() {

        const {dataSource,isLoading,count,page,pageSize,limitedaccess} = this.state;
        const {iscustomer,ismember } = this.state

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })


        let columns = [

            {
                Header: "Email",
                accessor: "email",
                // width: 150
            },
            {
                Header: "username",
                accessor: "username",
                // width: 150
            },

            {
                Header: "first_name",
                accessor: "first_name",
                // width: 150
            },
            {
                Header: "last_name",
                accessor: "last_name",
                // width: 150
            },
            {
                Header: "is member",
                accessor: "ismember",
                // width: 150
            },

            {
                Header: "status",
                accessor: "status",
                // width: 150
            },




            {
                Header: "referral",
                accessor: "referral"
            },
            {
                Header: "myreferral",
                accessor: "myreferral"
            },
            {
                Header: "creditdecember",
                accessor: "creditdecember"
            },


            {
                Header: "Membership expiry",
                accessor: "membershipexpiry"
            },

            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]



        if(limitedaccess){
            columns = [
                {
                    Header: "Email",
                    accessor: "email",
                    // width: 150
                },
                {
                    Header: "username",
                    accessor: "username",
                    // width: 150
                },
            ]
        }



        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left"> List</CardTitle>

                                    {!limitedaccess &&<Button color="primary" className="pull-right" onClick={() =>
                                        this.setState({
                                            iscustomer:false,
                                            ismember:!ismember,
                                        },()=>{
                                            this.callPage()
                                        })
                                    }>
                                        {!ismember?"MEMEBER ONLY":"Show All"}
                                    </Button>}

                                    {!limitedaccess &&<Button color="primary" className="pull-right" onClick={() =>
                                        this.setState({
                                            iscustomer:!iscustomer,
                                            ismember:false,
                                        },()=>{
                                            this.callPage()
                                        })
                                    }>
                                        {!iscustomer?"CUSTOMER ONLY":"Show All"}
                                    </Button>}
                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page, state.pageSize,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
});
export default connect(null, mapDispatchToProps)(ClientList);
