import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import strings from "../../core/translate";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";


export default class DayAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id: "",
            title: "",
            description:"",
            orderx:"0",
            coin:"0",
            date:"",
            mission:[],
            allmission:[],
            loading: false,
        };
        this.pathname = "day";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                ...edit,
                date: edit.date ? new Date(edit.date).toISOString().substr(0, 10) : "",

            });
        }
        this.prepareData();

    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("mission");
        if (responseuni) {
            const data = responseuni.data.data;
            let allmission = []
            data.map((p) => {
                allmission.push({ value: p._id, label: p.title+" / "+p.dayNames?.join(",") });
            });
            this.setState({
                allmission,
            });
        }
    };


    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { _id, title,description,coin,mission,orderx,date } = this.state;


        let missionid = []
        mission.map(i=>{
            if(i.value){
                missionid.push(i.value)
            }else{
                missionid.push(i)
            }
        })
        const data = {
            title,
            _id,
            date,
            description,
            coin,
            orderx,
            mission:missionid
        };

        const response = await this.engine.saveItem(this.pathname, data)

        if (response && response.status === 200) {
            this.props.history.push('/admin/' + this.pathname);
        }
        this.setState({
            loading: false
        })
    };



    render() {
        // taking all the states
        const { loading, isLoading, title,date, description, coin,allmission,mission,orderx } = this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{strings.information}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label={"title"} name="title" data={title} changeInput={this.changeInput.bind(this)} />


                                        <FormRow label={"date"} name="date" data={date} type={"date"} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label={"order"} name="orderx" data={orderx} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"description"} type={"textarea"} name="description" required={false} data={description} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label={"coin"} name="coin" data={coin} changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="mission" isMulti={true}  name="mission"   data={allmission}  defaultValue={mission && mission.map((item) => {
                                            return allmission.find(v => v.value === item.value || v.value === item.id || v.value === item)
                                        })} changeInput={data =>
                                            this.setState({ mission: data })
                                        }  />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>{strings.save}</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


