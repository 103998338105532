import Login from "views/login/Login.jsx";
import ManagerList from "./views/manager/ManagerList";
import ManagerTypeList from "./views/managertype/ManagerTypeList";
import ManagerTypeAddUpdate from "./views/managertype/ManagerTypeAddUpdate";
import strings from "./core/translate";
import ClientAddUpdate from "./views/client/ClientAddUpdate";
import PaymentAddUpdate from "./views/payment/PaymentAddUpdate";
import InvestAddUpdate from "./views/investment/InvestAddUpdate"
import QuestionList from "./views/question/QuestionList";
import QuestionAddUpdate from "./views/question/QuestionAddUpdate";
import CategoryAddUpdate from "./views/category/CategoryAddUpdate";
import QuestionCategorylist from "./views/category/CategoryList";
import RuleList from "./views/rule/RuleList";
import RuleAddUpdate from "./views/rule/RuleAddUpdate";
import TransactionList from "./views/transaction/TransactionList";
import UserList from "./views/user/UserList";
import UserAddUpdate from "./views/user/UserAddUpdate"
import TransactionAddUpdate from "./views/transaction/TransactionAddUpdate";
import CourseList from "./views/course/CourseList";
import CourseAddUpdate from "./views/course/CourseAddUpdate";
import ReportList from "./views/report/ReportList";
import BannerAddUpdate from "./views/banner/BannerAddUpdate";
import BannerList from "./views/banner/BannerList";
import ManagerAddUpdate from "./views/manager/ManagerAddUpdate";
import LevelAddUpdate from "./views/level/LevelAddUpdate";
import NotificationList from "./views/notification/NotificationList";
import NotificationAddUpdate from "./views/notification/NotificationAddUpdate";
import MissionList from "./views/mission/MissionList";
import PlanList from "./views/plan/PlanList";
import PlanAddUpdate from "./views/plan/PlanAddUpdate";
import MissionAddUpdate from "./views/mission/MissionAddUpdate";
import RankList from "./views/rank/RankList";
import RankAddUpdate from "./views/rank/RankAddUpdate";
import ContentList from "./views/content/ContentList";
import ContentAddUpdate from "./views/content/ContentAddUpdate";
import InvestmentList from "./views/investment/InvestmentList";
import GptAddUpdate from "./views/gpt/GptAddUpdate";
import NewsList from "./views/news/NewsList";
import NewsAddUpdate from "./views/news/NewsAddUpdate";
import UserProfitList from "./views/userprofit/UserProfitList";
import UserProfitAddUpdate from "./views/userprofit/UserProfitAddUpdate";
import UserMonthlyReport from "./views/user/UserMonthlyReport";
import RequestList from "./views/request/RequestList";
import Userprofileadvanced from "./views/userprofit/Userprofileadvanced";
import SubtitleAddUpdate from "./views/subtitle/SubtitleAddUpdate";
import UserMissionList from "./views/usermission/UserMissionList";
import UserMissionAddUpdate from "./views/usermission/UserMissionAddUpdate";
import UserReports from "./views/reports/UserReports";
import CommentsList from "./views/comments/CommentsList";
import ListDay from "./views/day/ListDay";
import DayAddUpdate from "./views/day/DayAddUpdate";

    document.body.classList.remove('langar');
    strings.setLanguage('en');


let routes = [

    {
        path: "/report",
        name: "dashboard",
        menu: true,
        allow: ["admin","manager"],
        component:  ReportList,
        icon: "nc-icon nc-paper",
        layout: "/admin"
    },
    {
        path: "/learn",
        name: "learn",
        classItem:"menuItem",
        allow: ["admin","manager","content","support"],
        icon: "nc-icon nc-shop",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse0",
        views: [
            {
                path: "/category",
                name: "category",
                menu: true,
                allow : ["admin","content","manager","assistant","educational guide"],
                component: QuestionCategorylist,
                layout: "/admin"
            },
            {
                path: "/news",
                name: "news",
                menu: true,
                allow : ["admin","content","manager","assistant","educational guide"],
                component: NewsList,
                layout: "/admin"
            },
            {
                path: "/question",
                name: "questions",
                menu: true,
                allow : ["admin","content","manager","assistant","educational guide"],
                component: QuestionList,
                layout: "/admin"
            },

            {
                path: "/course",
                name: "course",
                menu: true,
                allow : ["admin","content","manager","assistant","educational guide"],
                component:  CourseList,
                layout: "/admin"
            },
            {
                path: "/day",
                name: "day",
                menu: true,
                allow : ["admin","content","manager","assistant","educational guide","support"],
                component:  ListDay,
                layout: "/admin"
            },
            {
                path: "/mission",
                name: "mission",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide","support"],
                component: MissionList,
                layout: "/admin"
            },
            {
                path: "/missionuser",
                name: "mission user",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide","support"],
                component: UserMissionList,
                layout: "/admin"
            },





        ]
    },


    {
        path: "/member",
        name: "member",
        classItem:"menuItem",
        allow: ["admin","manager","support"],
        icon: "nc-icon nc-badge",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse340",
        views: [
            {
                path: "/request",
                name: "request",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: RequestList,
                layout: "/admin"
            },
            {
                path: "/user",
                name: "user",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide","support"],
                component: UserList,
                layout: "/admin"
            },
            {
                path: "/userreports",
                name: "user report",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: UserReports,
                layout: "/admin"
            },



            {
                path: "/transaction",
                name: "transaction",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: TransactionList,
                layout: "/admin"
            },

            {
                path: "/plan",
                name: "plan",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: PlanList,
                layout: "/admin"
            },

            {
                path: "/rank",
                name: "rank",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: RankList,
                layout: "/admin"
            },

            {
                path: "/comments",
                name: "comments",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: CommentsList,
                layout: "/admin"
            },


            {
                path: "/leg",
                name: "Legs",
                menu: true,
                allow : ["admin","it","manager","assistant","educational guide"],
                component: RuleList,
               // icon: "nc-icon nc-paper",
                layout: "/admin"
            },
            {
                path: "/banner",
                name: "banner",
                menu: true,
               // icon: "nc-icon nc-paper",
                allow : ["admin","it","manager","assistant","educational guide"],
                component: BannerList,
                layout: "/admin"
            },


        ]
    },




    {
        path: "/content/add",
        name: "add content",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ContentAddUpdate,
        layout: "/admin"
    },
    {
        path: "/content/edit/:id",
        name: "edit content",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ContentAddUpdate,
        layout: "/admin"
    },


    {
        path: "/day/add",
        name: "add day",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: DayAddUpdate,
        layout: "/admin"
    },
    {
        path: "/day/edit/:id",
        name: "edit day",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: DayAddUpdate,
        layout: "/admin"
    },


    {
        path: "/missionuser/edit/:id",
        name: "edit missionuser",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserMissionAddUpdate,
        layout: "/admin"
    },


    {
        path: "/news/add",
        name: "add news",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: NewsAddUpdate,
        layout: "/admin"
    },
    {
        path: "/news/edit/:id",
        name: "edit news",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: NewsAddUpdate,
        layout: "/admin"
    },


    {
        path: "/plan/add",
        name: "add plan",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PlanAddUpdate,
        layout: "/admin"
    },
    {
        path: "/plan/edit/:id",
        name: "edit plan",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PlanAddUpdate,
        layout: "/admin"
    },

    {
        path: "/rank/add",
        name: "add rank",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RankAddUpdate,
        layout: "/admin"
    },
    {
        path: "/rank/edit/:id",
        name: "edit rank",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RankAddUpdate,
        layout: "/admin"
    },

    {
        path: "/mission/add",
        name: "add mission",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: MissionAddUpdate,
        layout: "/admin"
    },
    {
        path: "/mission/edit/:id",
        name: "edit mission",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: MissionAddUpdate,
        layout: "/admin"
    },


    {
        path: "/client/add",
        name: "add client",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ClientAddUpdate,
        layout: "/admin"
    },
    {
        path: "/client/edit/:id",
        name: "edit client",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ClientAddUpdate,
        layout: "/admin"
    },

    {
        path: "/userprofit/add",
        name: "add userprofit",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserProfitAddUpdate,
        layout: "/admin"
    },
    {
        path: "/userprofit/edit/:id",
        name: "edit userprofit",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserProfitAddUpdate,
        layout: "/admin"
    },

    {
        path: "/investment/add",
        name: "add investment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: InvestAddUpdate,
        layout: "/admin"
    },
    {
        path: "/investment/edit/:id",
        name: "edit investment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: InvestAddUpdate,
        layout: "/admin"
    },

    {
        path: "/notification/add",
        name: "add notification",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: NotificationAddUpdate,
        layout: "/admin"
    },
    {
        path: "/notification/edit/:id",
        name: "edit notification",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: NotificationAddUpdate,
        layout: "/admin"
    },






    {
        path: "/subtitle/add",
        name: "add subtitle",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: SubtitleAddUpdate,
        layout: "/admin"
    },
    {
        path: "/subtitle/edit/:id",
        name: "edit subtitle",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: SubtitleAddUpdate,
        layout: "/admin"
    },

    {
        path: "/level/add",
        name: "add level",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: LevelAddUpdate,
        layout: "/admin"
    },
    {
        path: "/level/edit/:id",
        name: "edit level",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: LevelAddUpdate,
        layout: "/admin"
    },



    {
        path: "/investment/add",
        name: "add investment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: InvestAddUpdate,
        layout: "/admin"
    },
    {
        path: "/investment/edit/:id",
        name: "edit investment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: InvestAddUpdate,
        layout: "/admin"
    },
    {
        path: "/question/add",
        name: "add question",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: QuestionAddUpdate,
        layout: "/admin"
    },
    {
        path: "/question/edit/:id",
        name: "edit question",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: QuestionAddUpdate,
        layout: "/admin"
    },
    {
        path: "/category/add",
        name: "add category",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },
    {
        path: "/category/edit/:id",
        name: "edit question",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },
    {
        path: "/leg/add",
        name: "add leg",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RuleAddUpdate,
        layout: "/admin"
    },
    {
        path: "/leg/edit/:id",
        name: "edit leg",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: RuleAddUpdate,
        layout: "/admin"
    },
    {
        path: "/user/add",
        name: "add user",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/user/edit/:id",
        name: "edit user",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/user/month/:id",
        name: "user month",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: UserMonthlyReport,
        layout: "/admin"
    },
    {
        path: "/course/add",
        name: "add course",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CourseAddUpdate,
        layout: "/admin"
    },
    {
        path: "/course/edit/:id",
        name: "edit course",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CourseAddUpdate,
        layout: "/admin"
    },

    {
        path: "/banner/add",
        name: "add banner",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BannerAddUpdate,
        layout: "/admin"
    },
    {
        path: "/banner/edit/:id",
        name: "edit banner",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BannerAddUpdate,
        layout: "/admin"
    },


    {
        path: "/transaction/edit/:id",
        name: "edit transaction",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: TransactionAddUpdate,
        layout: "/admin"
    },


    {
        path: "/payment/add",
        name: "add payment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PaymentAddUpdate,
        layout: "/admin"
    },
    {
        path: "/payment/edit/:id",
        name: "edit payment",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: PaymentAddUpdate,
        layout: "/admin"
    },





    {
        path: "/login",
        name: "Login",
        mini: "L",
        menu: false,
        component: Login,
        layout: "/auth"
    },






    {
        path: "/managertype/edit/:id",
        name: "Edit manager type",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerTypeAddUpdate,
        layout: "/admin"
    },
    {
        path: "/managertype/add",
        name: "add manager type",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerTypeAddUpdate,
        layout: "/admin"
    },









    {
        path: "/manager",
        name: strings.maangx,
       // menu: true,
       // allow : ["admin","it","manager","assistant","educational guide"],
        icon: "nc-icon nc-badge",
        component: ManagerList,
        layout: "/admin"
    },
    {
        path: "/manager/add",
        name: "add manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ManagerAddUpdate,
        layout: "/admin"
    },






    {
        path: "/admin",
        name: "Admin",
        classItem:"menuItem",
        allow: ["admin","manager"],
        icon: "nc-icon nc-badge",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse4",
        views: [

            {
                path: "/manager",
                name: "Manager",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: ManagerList,
                layout: "/admin"
            },
            {
                path: "/managertype",
                name: "Manager type",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: ManagerTypeList,
                layout: "/admin"
            },
            {
                path: "/userprofit",
                name: "User Fees",
                 menu: true,
                allow : ["admin","it"],
              //  icon: "nc-icon nc-badge",
                component: UserProfitList,
                layout: "/admin"
            },
            {
                path: "/userprofitadvanced",
                name: "User Fees Advanced",
                menu: true,
                allow : ["admin","it"],
                //  icon: "nc-icon nc-badge",
                component: Userprofileadvanced,
                layout: "/admin"
            },


            {
                path: "/notification",
                name: "notification",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: NotificationList,
                layout: "/admin"
            },
            {
                path: "/content",
                name: "content",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: ContentList,
                layout: "/admin"
            },
            {
                path: "/investment",
                name: "investment",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: InvestmentList,
                layout: "/admin"
            },
            {
                path: "/gpt/add",
                name: "Multyncome AI",
                menu: false,
                 allow : ["admin"],
               // icon: "nc-icon nc-settings-gear-65",
                component: GptAddUpdate,
                layout: "/admin"
            },

        ]
    },









];



export default routes;
